import React, {useState, useEffect} from 'react';
import './Career.css';
import {Link} from 'react-router-dom';
import Header from '../../Components/Navbar/Header';
import axios from 'axios';
import {Card, Row, Col, Button} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import {motion} from 'framer-motion';
import Footer from '../../Components/Footer/Footer';


function Career() {
  const [posts, setPosts] = useState([]);
  const [visible, setVisible] = useState(3);
  const loadMore = () => {
    setVisible(visible + 3)
  }


  useEffect(() => {
    axios.get(`https://api.logicode.id/api/careers`)
    .then(res => {
        console.log(res)
        setPosts(res.data.data)
    })
    .catch(err =>{
        console.log(err)
    })
    },[])

  
  return (
    <div>
      <Header />
    <div className="career-hero">
    <Link to="/" className="hero-header-link hero-header-link-bold">Home</Link> 
          <div className="hero-header-slash">/</div>
        <Link to="/services" className="hero-header-link ">Career</Link>

        <div className="services-overlay">
        <div className="services-overlay-text">We Value Your Various Needs</div>
        <div className="services-overlay-sub">We use the latest tools and processor inherited from Silicon Valley</div>
      </div>
    </div>


    <div className="career-body">

      <div className="career-body-sub">Explore open opportunities</div>
      <div className="career-body-title">Elevate your career to the next level</div>
      <div className="career-body-caption">This is the sign you're looking for. Push beyond your limit and put your skills to shape the tech landscape with us.</div>
    <div id="grid-career">
    {posts.slice(0, visible).map(post => (
                  <motion.div initial="hidden"
                  whileInView="visible"
                  transition={{ duration: 0.8 }}
                  variants={{
                    visible: { opacity: 1, y:0},
                    hidden: { opacity: 0, y:50}
                  }}>
                    <Card className="cards-career" key={post.id} >
                      <Row nogutters className="rows-career">
                        <Col nogutters xs={10}><div className="card-career-title">{post.career}</div></Col>
                      </Row>
                      <div>{post.type.type}</div>
                      <hr className="career-line"></hr>
                      <div className="card-career-exp">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]} >{post.detail}</ReactMarkdown>
                      </div>
                      <hr className="career-line"></hr>

                      <a href="mailto:admin@logicode.id" target='_blank'>
                      <Button className="career-button">Apply via Email</Button></a>
                    </Card>
                  </motion.div>
                ))}
      </div>

      <Button onClick={loadMore} className="section-btn-load-more">Load More</Button>

    </div>
    <Footer />









    </div>
  )
}

export default Career