import React from 'react';
import './Process.css';
import Header from '../../Components/Navbar/Header';
import {Link } from 'react-router-dom';


import Section2 from './Components/Section2/Section2';
import Footer from '../../Components/Footer/Footer';
import Section3 from './Components/Section3/Section3';

function Process() {
  return (
    <div>
      <Header />
      <div className="process-hero">
        <Link to="/" className="hero-header-link hero-header-link-bold">Home</Link> 
          <div className="hero-header-slash">/</div>
        <Link to="/process" className="hero-header-link ">Process</Link>

        <div className="process-overlay">
        <div className="process-overlay-text">All of your tech solutions, in one place.</div>
        <div className="process-overlay-sub">We select talents to set you up for success</div>
      </div>
      </div>

      <Section3 />

      <Section2 />
      <Footer />



    </div>
  )
}

export default Process