import React, {useState} from 'react';
import './Contact.css';
import {Row, Col, Form, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import Header from '../../Components/Navbar/Header';
import Footer from '../../Components/Footer/Footer';
import axios from 'axios';

function Contact() {
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  })

  function submit(e){
    e.preventDefault();
    axios.post('https://api.logicode.id/api/contact-form/submit',
      {
        'firstname': data.firstname,
        'lastname': data.lastname,
        'email': data.email,
        'phone': data.phone,
        'subject': data.subject,
        'message': data.message,
        })
        .then(res => {
          console.log(res.data)
        })

        .then(res => {
          alert('form submitted');
          window.location.reload();
        })

      }


  function handle(e){
    const newdata={...data}
     newdata[e.target.id] = e.target.value
     setData(newdata);

  }


  return (
    <div>

        <Header />
      <div className="services-hero">
        <Link to="/" className="hero-header-link hero-header-link-bold">Home</Link> 
          <div className="hero-header-slash">/</div>
        <Link to="/contact" className="hero-header-link ">Contact</Link>

 
        <div className="services-overlay">
        <div className="services-overlay-text">It all starts with the right people</div>
        <div className="services-overlay-sub">
        Our approach may provide the appropriate talents that match your goals.          </div>
      </div>
      </div>

      <div className="contact-body">
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className="contact-title">Opening Hours</div>
          <div className="contact-exp">
            Daily: 
            <span className="contact-desc"> 9.30 AM - 6.00 PM</span>
          </div>
          <div className="contact-exp">
          Sunday & Holiday:
            <span className="contact-desc"> Closed</span>
          </div>

          <div className="contact-title">Contact Info</div>
          <div className="contact-exp">
          Tokopedia Tower<br></br>
          Lt. 22 unit F, Semanggi<br></br>
          Jakarta Selatan, DKI Jakarta<br></br>
          12950
          </div>

          <div className="contact-email">
          <a href="mailto:admin@logicode.id" target='_blank' className="text-decoration-none-contact">admin@logicode.id</a>
          </div>
          <div className="contact-exp">
          <a href="https://wa.me/6282329347212" target='_blank' className="text-decoration-none-contact">+62 82329347212</a> 
          </div>

          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className="contact-us">
                  <span className="contact-us-title">Hi, How can we help you?</span>
                  <Form onSubmit={(e)=> submit(e)}>
                      <Form.Group>
                       <Row className="form-group">
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}> 
                          <Form.Control type="text" placeholder="First Name" onChange={(e) => handle(e)} name="firstname" id="firstname" value={data.firstname} className="form-small" /> </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Control type="text" placeholder="Last Name" onChange={(e) => handle(e)} name="lastname" id="lastname" value={data.lastname} className="form-small" /> </Col>
                        </Row>

                        <Row className="form-padding">
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}> 
                          <Form.Control type="email" placeholder="Email" onChange={(e) => handle(e)} name="email" id="email" value={data.email} className="form-small" /> </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Control type="tel" placeholder="Phone Number" onChange={(e) => handle(e)} name="phone" id="phone" value={data.phone} className="form-small" /> </Col>
                        </Row>

                        <Row className="form-padding">
                          <Col xs={12}>
                          <Form.Control type="text" placeholder="Subject" onChange={(e) => handle(e)} name="subject" id="subject" value={data.subject} className="form-small" />
                          </Col>
                        </Row>


                        <Row className="form-padding">
                        <Col xs={12}>

                        <Form.Control as="textarea" rows={3}  placeholder="Message" onChange={(e) => handle(e)} name="message" id="message" value={data.message} className="form-small" />
                        </Col>
                        </Row>
                        </Form.Group>


                      <Button variant="primary" type="submit" className="form-button">
                        Send Message
                      </Button>
                  </Form>

                  


              </div>   
          </Col>

          
        </Row>


      </div>

      <Footer />


    </div>
  )
}

export default Contact