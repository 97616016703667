import React, {useRef} from 'react';
import './Section2.css';
import {Row, Col} from 'react-bootstrap';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';
import Img from '../../../../assets/1.jpg';

import {motion, useScroll, useTransform} from 'framer-motion';




function Home2content() {
  const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end end"]
    });

    const variants = {
      visible: { opacity: 1, x: 0, transition:{duration: 1} },
      hidden: { opacity: 0, x: 50  },
  }

    const tabitem =useTransform(scrollYProgress, [0, 0.55, 0.9, 1], [0, 1, 1, 1])
  return (
    <div ref={containerRef}>
    <motion.div className="tab-content" initial="hidden" whileInView="visible" variants={variants} style={{opacity: tabitem}}>
        <Row nogutters className="image-col">
        <Col xs={12} sm={12} md={12} lg={8} className="tab-align">
        <div className="tab-content-title">IT Business Development</div>
        <div classname="tab-content-text">
        Stand firm in the digital world with innovative tech solutions. Our business-smart and tech-savvy resources understand your point of view and will take you to your goals quickly.
        </div>

         
         <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1 grey-base">
                            Start your digital journey straight away
                            </div> <br></br>
                    </div>

                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1"/> 
                            <div className="tab-value1 grey-base">
                            Experience only global standards in IT practices such as software development, product enhancement, and technical strategy 
                            </div><br></br>
                    </div>

                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1 grey-base">
                            Innovative and market-proven solutions for a wide range of IT initiatives
                            </div>
                    </div>
        
        
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} >
          <div className="tab-image-placement">
            <img src={Img} alt="" className="tab-img" />
            </div>
        </Col>
     </Row>
    </motion.div>
    </div>

  )
}

export default Home2content