import React from 'react';
import './Section2.css'; 
import {Carousel, Row, Col} from 'react-bootstrap';
import Waterfall from '../../../../assets/waterfall.png';
import Agile from '../../../../assets/agile.png';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';

function Section2() {
  return (
    <div className="process-section2-body">
        <Carousel interval={null}>
        <Carousel.Item>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6}>
                    <div className="method-text-place">
                        <div className="method-title">
                        Agile method
                            </div>
                    <div className="method-text">
                    A flexible and collaborative project management approach loved by the tech community.<br></br><br></br> 

Focusing on the rapid advancement of the tech landscape, Agile is the latest preferred methodology for software development and other similar projects. With Agile, your team can accommodate changing requirements, adapt quickly to new possibilities and generate incremental output in each "sprint" until the end of the project.

<br></br><br></br>Our team recommends this method for a more hands-on client with a dynamic project.
                    </div>
                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1">
                            Flexible and collaborative with Agile light documentation work.
                            </div> <br></br>
                    </div>

                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1"/> 
                            <div className="tab-value1">
                            Navigate and pivot around changes swiftly with less time spent on meeting and documenting.
                            </div><br></br>
                    </div>

                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1">
                            Collaborate intensely and celebrate your team's individuality. Agile rewards autonomy to a whole other level.
                            </div>
                    </div>
                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1">
                            Have a working product even before your project ends; Each sprint in a project should produce a functioning result you can be proud of.
                            </div>
                    </div>
                    </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6}>
                    <div className="image-place-process">
                    <img src={Agile} alt="" className="process-img" />
                    </div>
                    </Col>
                </Row>
            </Carousel.Item>
            <Carousel.Item>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6}>
                        <div className="image-place-process">
                    <img src={Waterfall} alt="" className="process-img" /> 
                    </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6}>
                    <div className="method-text-place">
                        <div className="method-title">
                            Waterfall Method
                            </div>
                    <div className="method-text">
                    Get all the things laid out before you make your first move.<br></br> <br></br> 

                    This tried-and-trusted methodology defines the big picture and details at the beginning of the project. With clear steps and a leadership structure to begin, your team has a more comprehensive view of what to do. Phases in the waterfall method come in succession so you can set the tone and visualize clearly what a successful project or product should feature. With the testing stage, you can be confident of the product quality and functionality based on the specifications you documented earlier.

                    <br></br> <br></br> Exercise greater control and have a solid long-term strategy with the waterfall method. 
                    </div>
                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1">
                            A reliable, time-tested methodology for a conservative project approach
                            </div> <br></br>
                    </div>

                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1"/> 
                            <div className="tab-value1">
                            Visualize your win early in the project. Waterfall's successive phase offers clarity and better workload/timeline projection.
                            </div><br></br>
                    </div>

                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1">
                            Easier resource planning, as Waterfall lets you define explicitly the skillset you need for a successful product/project early in the process.
                            </div>
                    </div>

                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1">
                            Peace of mind -- Waterfall's clear chain of command means you will always be aware of your project progress.
                            </div>
                    </div>
                    </div>
                    </Col>
                </Row>
            </Carousel.Item>
            

        </Carousel>
    </div>
  )
}

export default Section2