import React, {useState, useEffect} from 'react';
import './Section1.css';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

function Section1() {
  let {id} = useParams();

  const [post, setPost] = useState([]);


  useEffect(() => {
    axios.get(`https://api.logicode.id/api/article/${id}`)
    .then(res => {
        console.log(res)
        setPost(res.data.data[0])
    })
    .catch(err =>{
        console.log(err)
    })
    },[])
  return (
    <div className="blogdetails-section1-body">
     <div className="blogdetails-section1-sub">/ {post.category}</div>
     <div className="blogdetails-author">By. {post.author}</div> 

     <div className="blogdetails-section1-text">
     <ReactMarkdown rehypePlugins={[rehypeRaw]} >{post.content}</ReactMarkdown>
      </div><br></br>

      <div className="blogdetails-section1-img-place">
      <img src={`https://api.logicode.id/${post.thumbnail_path}`} alt="" className="blogdetails-section1-img" />
      </div>

    <div className="tag-title">Tags</div>
      <div className="tag-body-place">
                {post.tag?.map(post =>
                  <div className="tag-body">
                    {post}
                  </div>
                  )}
              </div>
      




    </div>
  )
}

export default Section1