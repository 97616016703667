import React, {useState, useEffect} from 'react';
import './Section1.css';
import {Row, Col} from 'react-bootstrap';
import Blog1 from '../../../../assets/blog_1.png';
import {Link} from 'react-router-dom';
import axios from 'axios';


function Section1() {

    const [posts, setPosts] = useState([]);
    // const [visible, setVisible] = useState(3);

    // const loadMore = () => {
    // setVisible(visible + 3)
    // }

    useEffect(() => {
        axios.get('https://api.logicode.id/api/articles/All')
        .then(res => {
            console.log(res)
            setPosts(res.data.data)
        })
        .catch(err =>{
            console.log(err)
        })
    },[])

    useEffect(() => {
        const circle = document.querySelector('.cursor2');
        const div1 = document.getElementsByName('blog-image-all');

        function buttonShow() {
            div1.forEach((divs1) => {
                divs1.addEventListener('mousemove', (e) => {
                circle.style.opacity = 1;
                circle.style.left = e.pageX -50  + 'px';
                circle.style.top = e.pageY -50  + 'px';
                });

                divs1.addEventListener('mouseleave', e => {
                circle.style.opacity = 0;
            })
        });
        }
        buttonShow();
       
      });


  return (
    <div className="blog-section1-body">
        <div className="blog-section1-sub">
            / What We Do
        </div>
        <div className="blog-section1-title">
        IT Consulting and Resource Provider
        </div>


        {/* <div className="blog-nav-links">
            All
        </div> */}
        <hr></hr>

        <div id="grid">
            {posts.map(post => (
                <section key={post.id}><div className="cursor2"> More</div>
                <div className="blog-image-place">
                    <Link to={`/blogdetails/${post.id}`}>
                        <img src={`https://api.logicode.id/${post.thumbnail_path}`} name="blog-image-all" alt="" className="blog-image" />  
                    </Link>
                    </div>
                    <div className="blog-list-sub">{post.category} <hr className="blogline"></hr> </div>
                    <div className="blog-list-title">{post.title}</div>
                    <div className="blog-list-text">{post.caption}</div>


                </section>  
            ))}

        </div>


    </div>
  )
}

export default Section1