import React, {useRef, useEffect, useState} from 'react';
import './PortfolioList.css';
import {motion, useScroll} from 'framer-motion';
import {Link } from 'react-router-dom';
import { Parallax } from "react-parallax";
import axios from 'axios';


function PortfolioList() {

    const [posts, setPosts] = useState([]);
    // const [visible, setVisible] = useState(3);

    // const loadMore = () => {
    // setVisible(visible + 3)
    // }
    

    useEffect(() => {
        axios.get('https://api.logicode.id/api/portfolios')
        .then(res => {
            console.log(res)
            setPosts(res.data.data)
        })
        .catch(err =>{
            console.log(err)
        })
    },[])
    


    const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end end"]
    });




  return (
    <div className="portfoliolist-body" ref={containerRef} ><div className="cursor2"> More</div>
        <motion.div className="portfoliolist-sub"  >
            / What We Do</motion.div>
        <motion.div className="portfoliolist-title">
            Digital Consulting and Talents Provider</motion.div>



            <div id="grid">
                {posts.map(post => (
                    <section key={post.id}>
                        <div className="portfoliolist-image-place">
                            
                                <Parallax bgImage={`https://api.logicode.id/${post.thumbnail_path}`} alt="" className="portfoliolist-image" strength={150}>
                                    <Link to={`/portfoliodetails/${post.id}`}>
                                    <div className="parallax-inside-div"></div>
                                    </Link>
                                    </Parallax>  

                                    
                                </div>
                                <motion.div className="portfolioimage-sub" >
                                {post.industry} </motion.div>
                                <motion.div className="portfolioimage-title">
                                 {post.client}</motion.div>
                                <motion.div className="portfolioimage-exp">
                                   {post.client_description} </motion.div>


                    </section>
                ))}
            </div>


       

        


    </div>
  )
}

export default PortfolioList