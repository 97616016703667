import React, {useState, useLayoutEffect} from 'react';
import './Header.css';
import Logo from '../../assets/logicode.png';
import { Spiral as Hamburger } from 'hamburger-react';
import {motion, AnimatePresence} from 'framer-motion';
import Navigation from './Navigation';
import {Link} from 'react-router-dom';



function Header() {
  const variants = {
    visible: { opacity: 1, y: 0, transition:{duration: 1} },
    hidden: { opacity: 0, y: -50 },
  };

  const variants2 = {
    visible: { opacity: 1, y: 0, transition:{duration: 1} },
    hidden: { opacity: 0, y: -50 },
  };

  const[show, setShow] = useState(false);

  const close = () => setShow(false);
  const open = () => setShow(true);

  useLayoutEffect(() => {
    if (show === true){
      document.body.style.overflow = "hidden"
    } else{
      document.body.style.overflow = "auto"
    }
  })



  return (
    <>
      <div className="header" id="header">
        <motion.div className="logo" initial="hidden" animate="visible" variants={variants}>
         <Link to="/"><img src ={Logo} alt="" className="logo-image" /></Link> 
        </motion.div>
        <motion.button className="hamburger" initial="hidden" animate="visible" variants={variants2} 
        whileHover={{ scale: 0.9 }}
        onClick={() => (show ?  close() : open() )}
        
        >
        <Hamburger color="#fff" />
        </motion.button>
       </div>

       


      <AnimatePresence  initial={false}
        mode="wait"
        onExitComplete={() => null}>

      {show && <Navigation show={show} handleClose={close} />}

       
         
      </AnimatePresence>



    </>
  )
}

export default Header