import React from 'react';
import './Section1.css';
import Image from '../../../../assets/19.png';
import {Row, Col} from 'react-bootstrap';
import {motion} from 'framer-motion';

function HomeContent4() {


    const variants = {
        visible: { opacity: 1, transition:{duration: 1} },
        hidden: { opacity: 0,  },
    }

    

  return (
    <div className="content">
        <Row>
            <Col sm={12} xs={12} md={12} lg={12} xl={6}>
            <motion.div className="homecontent1-image-place" initial="hidden" whileInView="visible" variants={variants} >
                <img src={Image} alt="" className="homecontent-image-section" />
            </motion.div>
            </Col>

            <Col sm={12} xs={12} md={12} lg={12} xl={6}>
            <motion.div className="content-title" initial="hidden" whileInView="visible" variants={variants} >
            Next to you in every step.            
            </motion.div>
            <motion.div className="content-text" initial="hidden" whileInView="visible" variants={variants}>
            Communication is our middle name. Partnering with us guarantees that you'll be accompanied at every step of your way - all the way - until you reach your goal. Our team goes above and beyond to present the most fitting solution for your business tech needs.            
            </motion.div>
            
            </Col>


        </Row>


    </div>
  )
}

export default HomeContent4