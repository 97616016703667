import React from 'react';
import './Section2.css';
import { Row, Col} from 'react-bootstrap';
import Image from '../../../../assets/rec_4.png';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';

function Section2() {
  return (
    <div className="services-section2-body">
         <Row>
            <Col sm={12} xs={12} md={12} lg={12} xl={6}>
            <div className="services-section2-imageplace">
                <img src={Image} alt="" className="services-section2-imagesection" />
            </div>
            </Col>

            <Col sm={12} xs={12} md={12} lg={12} xl={6}>
                <div className="services-section2-title">
                Why Logicode
                </div>

                <div classname="services-section2-text">
                Your search for the right tech partner is over - Hi, we're Logicode.
          
        </div>


        {/* <div className="method-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam quam est, placerat non suscipit a, aliquam nec elit. Curabitur at efficitur ipsum, sit amet ultrices tortor. Integer arcu metus, sodales ac commodo at, imperdiet at justo.
                    </div> */}
                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1">
                            <span className="bold-red">We are your biggest supporter. </span>
                            Our recommendation is based on an in-depth understanding of your business vision, industry standards, and market trends.
                            </div> <br></br>
                    </div>

                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1"/> 
                            <div className="tab-value1">
                            <span className="bold-red">Collaborative tech experts. </span> 
                            Our tech professionals are always on top of their game. They are insightful lifelong learners with an interest in everything digital. We also routinely run workshops to elevate our team expertise.
                            </div><br></br>
                    </div>

                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1">
                            <span className="bold-red">Leading the tech implementation landscape. </span>
                            Our innovative solutions are paired with expertise in the latest technology to bring you a cutting-edge solution for your business.
                            </div>
                    </div>
        
            
            </Col>


        </Row>


    </div>
  )
}

export default Section2