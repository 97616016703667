import React from 'react'
import './Section1.css';

function Section1() {
  return (
    <div className="about-section1-body">
        <div className="about-section1-sub">
            /What We Do
            </div>

            <div className="about-section1-title">
            IT Consulting and Resource Provider
            </div>

            <div className="about-section1-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi scelerisque nec sem ut laoreet. Curabitur accumsan odio eu tristique blandit. Fusce facilisis leo a sollicitudin porta. Morbi aliquam pharetra interdum. Vivamus facilisis feugiat libero. Ut sed risus luctus ligula mattis elementum. Duis blandit suscipit eros, sed imperdiet felis efficitur vitae. Nam mollis eleifend metus in egestas. Sed vitae massa ex. Integer rutrum aliquam auctor. Ut imperdiet id erat sed rutrum.
            </div>
    </div>
  )
}

export default Section1