import React from 'react';

const Section3Details = ({section3Details}) => {
    const {title, number ,caption, list1, list2, list3} = section3Details;

    return(
        <div className="item">
            <div className="card-tab-header">
              <div className="number-card">{number}</div>  <div className="process-carousel-title">{title}</div>
            </div>
            <div className="process-carousel-caption">{caption}</div>
            <ul>
            <li className="process-carousel-list">{list1}</li>
            <li className="process-carousel-list">{list2}</li>
            <li className="process-carousel-list">{list3}</li>
            </ul>
        </div>
    )
}

export default Section3Details;