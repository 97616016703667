import React, {useRef} from 'react';
import './Section2.css';
import {Row, Col} from 'react-bootstrap';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';
import Img from '../../../../assets/2.jpg';

import {motion, useScroll, useTransform} from 'framer-motion';




function Home2content2() {
  const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end end"]
    });


    const variants = {
      visible: { opacity: 1, y: 0, transition:{duration: 1} },
      hidden: { opacity: 0, y: 50  },
  }

    const tabitem =useTransform(scrollYProgress, [0, 0.55, 0.9, 1], [0, 1, 1, 1])
  return (
    <div ref={containerRef}>
    <motion.div className="tab-content" initial="hidden" whileInView="visible" variants={variants} style={{opacity: tabitem}}>
        <Row nogutters  className="image-col">
        <Col xs={12} sm={12} md={12} lg={4} >
          <div className="tab-image-placement">
            <img src={Img} alt="" className="tab-img" />
            </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={8} className="tab-align">
        <div className="tab-content-title">Outstanding UI/UX Design</div>
        <div classname="tab-content-text">
        Customer is king -- we get it too. With UI/UX as your product frontline in facing your customers, it is important to get it right. Our team is a beautiful blend of industry-specific vets and creative all-rounder professionals to deliver your vision.
        </div>


                  <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1 grey-base">
                            Seamless user experience with imaginative UI/UX design
                            </div> <br></br>
                    </div>

                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1"/> 
                            <div className="tab-value1 grey-base">
                            Effective UI/UX solutions from research until go-live
                            </div><br></br>
                    </div>

                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1 grey-base">
                            Maximize your business potential with the right UI/IX design
                            </div>
                    </div>
        
        
        </Col>

     </Row>
    </motion.div>
    </div>

  )
}

export default Home2content2