import React from 'react';
import './Section3.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Section3Details from './Section3Details';
import {motion} from 'framer-motion';

const Section3 = () => {
    const proCess = [
        {title: 'Prep Work',
        number: '01',
        caption: 'We do preliminary work to understand your aspirations for this project. In this stage, our focus is conducting activities to examine what is truly needed and wanted from this project.',
        list1: 'Requirement, expectation, and scope breakdown',
        list2: "Defining the system configuration and techstacks to use",
        list3: 'Determining the project methodology and strategy based on your distinctive business positioning',
        },
        {title: 'Design & Development',
        number: '02',
        caption: 'The findings from the prep stage become our guideline for design and development. Our team transforms the guidelines into your project deliverables: UI/UX, back-end, integrated systems, and many more. ',
        list1: 'Workshops and feedback sessions to maintain congruence with the design and specs defined',
        list2: "Product prototypes based on conceptual design and clients' vision",
        list3: 'Continuation of the concept development from the prep stage, adjusting to recent information and product development progression',
        },
        {title: 'Testing & Validation',
        number: '03',
        caption: 'The testing & validation stage confirms your product meets the standard. Our tests are comprehensive, including but not limited to functional, performance, and security testing.',
        list1: "Verify the product works harmoniously with and integrates fully into the surrounding systems",
        list2: 'Some of the tests we do: System Testing, Integration Testing, Acceptance Testing, and Unit Testing ',
        list3: 'Verify product stability, security, and reliability ',
        },
        {title: 'Deployment',
        number: '04',
        caption: 'In this final stage, our team will prepare and compile all components required to assemble a flawless product operation (e.g., API, database, infrastructure) in the prod environment. We also provide documentation and hyper care post-deployment to ensure product longevity and stellar performance.',
        list1: 'System and application installation following the correct technical and configuration specifications',
        list2: 'Technical documentation and manual to support product takeover and maintenance',
        list3: 'Training for users and admins to use and maintain the product',
        },
        // {title: 'Onboarding',
        // caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id hendrerit lectus, eget vulputate enim. Donec placerat venenatis ante, sit amet scelerisque mauris pulvinar id. Donec aliquet purus mi',
        // list1: '',
        // list2: '',
        // list3: '',
        // }
    ]

    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: -30,
        autoplay: false,
        dots: true, 
        smartSpeed: 450,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1,
            },
            500: {
                items: 1,
            },
            600: {
                items: 1
            },
            1000: {
                items: 2
            },
            1200: {
                items: 3
            }
        }

    };



    const variants1 = {
        visible: { opacity: 1, x: 0, transition:{duration: 0.5} },
        hidden: { opacity: 0, x: -50 },
        };

        const variants2 = {
            visible: { opacity: 1, x: 0, transition:{duration: 0.5} },
            hidden: { opacity: 0, x: 50 },
            };
    

  return (
    <div className="about-section3-body">
        <motion.div className="about-section3-sub" initial="hidden" whileInView="visible" variants={variants2}>
        Our Methodology</motion.div>
        <motion.div className="about-section3-title" initial="hidden" whileInView="visible" variants={variants1}>
        The talent matchmaking process </motion.div>
        <motion.div className="about-section3-caption" initial="hidden" whileInView="visible" variants={variants2}>
        Qualification: check <br></br>
Technical background: check <br></br>
Soft-skill: check <br></br>

Learn how we make sure your upcoming resource is the best for you.</motion.div>
            <motion.div>
            <OwlCarousel id="process-carousel" className="" {...options}> 
            {
                proCess.length === 0 ?
                <div className="item">
                <div className="shadow-effect">
                    <p>lorem ipsum</p>
                    </div></div> :
                    proCess.map(section3Details => {
                        return(
                            <Section3Details section3Details={section3Details} key={section3Details._key} />
                        )
                    })
                    
            }
           

            </OwlCarousel> 
            </motion.div>
    </div>
  )
}

export default Section3