import React, {useLayoutEffect} from 'react';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import './Header.css';
import { AiFillLinkedin} from 'react-icons/ai';










function Navigation() {
    const variants1 = {
        visible: { 
            opacity: 1, y: 0, transition:{duration: 1, delay: 1} 
        },
        hidden: { 
            opacity: 0, y: 50 
        },
      };

    const dropIn={
        hidden:{
          opacity: 0,
    
        },
        visible:{
          opacity:1,
          transition:{
            duration: 2, type: "spring",
          }
    
        },
        exit:{
          opacity:0,
          transition:{
            duration: 2, type: "spring",}
        },
      };


      useLayoutEffect(() => {
        const circle = document.querySelector('.cursor');
        const div1 = document.querySelector('.cursor-pointer');

        div1.addEventListener('mousemove', e => {
          circle.style.opacity = 1;
          circle.style.display= 'block';
          circle.style.left = e.clientX -50  + 'px';
          circle.style.top = e.clientY -50  + 'px';
        });
        div1.addEventListener('mouseleave', e => {
          circle.style.opacity = 0;
          circle.style.display='none';
        });
      });



  return (
    <motion.div className="navbar bg" variants={dropIn} initial="hidden" animate="visible" exit="exit">
        <motion.div initial="hidden" animate="visible" variants={variants1} className="cursor-pointer">
        
        <li className="floating3"><Link to="/" className="nav-link" name="Home"  >Home</Link></li>
        <li className="floating1"><Link to="/portfolio" className="nav-link" name="Portfolio" >Portfolio</Link></li>
        <li className="floating2"><Link to="/process" className="nav-link" name="Process">Process</Link></li>
        <li className="floating1"><Link to ="/career" className="nav-link" name="Career">Career</Link></li>
        <li className="floating2"><Link to ="/contact" className="nav-link" name="Contact">Contact</Link></li>
        <li className="floating2"><Link to ="/services" className="nav-link" name="Services">Services</Link></li>
        <li className="floating3"><Link to ="/blog" className="nav-link" name="Blog">Blog</Link></li>
        <div className="cursor"></div>
        </motion.div>
       





        <div className="contact">
            <div className="contact-info"> Call us:
                <div ><a className="contact-detail" target='_blank' href="https://wa.me/6282329347212">+62 82329347212</a></div>
            </div>
            <div className="contact-info"> Mail to:
                <div ><a href="mailto:admin@logicode.id" className="contact-detail" target='_blank'>admin@logicode.id</a></div>
            </div>
            <div className="contact-info"> Connect With Us<br></br>
            <a href="https://www.linkedin.com/company/logicode-indonesia/" target='_blank' className="header-icon"><AiFillLinkedin /></a> 
            </div>
            
        </div>
    </motion.div>
  )
}

export default Navigation