import React from 'react';
import './About.css';
import {Link} from 'react-router-dom';
import Section1 from './Components/Section1/Section1';
import Header from '../../Components/Navbar/Header';
import ServicesSection1 from '../Services/Components/Section1/Section1';
import Footer from '../../Components/Footer/Footer';
import Section2 from './Components/Section2/Section2';


function About() {


  return (
    <div>
      <Header />
      <div className="about-hero">
  
      <Link to="/" className="hero-header-link hero-header-link-bold">Home</Link> 
          <div className="hero-header-slash">/</div>
        <Link to="/portfolio" className="hero-header-link ">About</Link>

        <div className="about-header-link-title">
        We Solve Problems<br></br> for your business with<br></br> various solutions
      </div>

      <div>
            <a id="scroll-btn" href="#section-2"></a> 
            </div>

      </div>

      <Section1 />
      <ServicesSection1 />
      <Section2 />
      
      <Footer />






    </div>
  )
}

export default About