import React, {useState} from 'react';
import './Section1.css';
import Section1Content from './Section1Content';
import Section1NavItem from './Section1NavItem';
import {Row, Col} from 'react-bootstrap';

import HomeContent1 from './HomeContent1';
import HomeContent2 from './HomeContent2';
import HomeContent3 from './HomeContent3';
import HomeContent4 from './HomeContent4';
import {motion} from 'framer-motion';



function Section1() {
  const [activeTab, setActiveTab] = useState("tab1");


    const variants = {
      visible: { opacity: 1, x: 0,  transition:{duration: 1} },
      hidden: { opacity: 0, x: -50  },
  }

  const variants1 = {
    visible: { opacity: 1, x: 0,  transition:{duration: 1} },
    hidden: { opacity: 0, x: 50  },
}



  return (
    <div className="home-section1" >
      <motion.div className="home-section1-sub" initial="hidden" whileInView="visible" variants={variants} >
          /Our Value</motion.div>
        <motion.div className="home-section1-title" initial="hidden" whileInView="visible" variants={variants} >
        Consistently bringing our clients vision to reality
        </motion.div>

        <div className="home-section1-tabs">
          <Row>
            <Col sm={12} xs={12} md={12} lg={12} xl={10}>

            <Section1Content id="tab1" activeTab={activeTab}>
              <HomeContent1 />
              </Section1Content>
              <Section1Content id="tab2" activeTab={activeTab}>
              <HomeContent2 />
              </Section1Content>
              <Section1Content id="tab3" activeTab={activeTab}>
              <HomeContent3 />
              </Section1Content>
              <Section1Content id="tab4" activeTab={activeTab}>
              <HomeContent4 />
              </Section1Content>


          </Col>

            <Col sm={12} xs={12} md={12} lg={12} xl={2} >
              <motion.ul className="homesection" initial="hidden" whileInView="visible" variants={variants1}>
            <Section1NavItem title="01" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab} className="section-nav" /> <div className="line"></div> 
            <Section1NavItem title="02" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab} className="section-nav"/>  <div className="line"></div>
            <Section1NavItem title="03" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab} className="section-nav"/> <div className="line"></div>
            <Section1NavItem title="04" id="tab4" activeTab={activeTab} setActiveTab={setActiveTab} className="section-nav"/>
            </motion.ul>
        </Col>
        </Row>
    </div>


    </div>
  )
}

export default Section1