import React from 'react';
import './Section1.css';
import Image from '../../../../assets/3.png';
import {Row, Col} from 'react-bootstrap';
import {motion} from 'framer-motion';

function Content1() {


    const variants = {
        visible: { opacity: 1, transition:{duration: 1} },
        hidden: { opacity: 0,   },
    }


    

  return (
    <div className="content" >
        <Row>
            <Col sm={12} xs={12} md={12} lg={12} xl={6}>
            <motion.div className="homecontent1-image-place" initial="hidden" whileInView="visible" variants={variants} >
                <img src={Image} alt="" className="homecontent-image-section" />
            </motion.div>
            </Col>

            <Col sm={12} xs={12} md={12} lg={12} xl={6}>
            <motion.div className="content-title" initial="hidden" whileInView="visible" variants={variants} >
            Work with high-caliber IT professionals </motion.div>
            <motion.div className="content-text" initial="hidden" whileInView="visible" variants={variants} >
            Get as specific as you want. We have a diverse range of talents to cover all your tech concerns. 
            </motion.div>
            
            </Col>


        </Row>


    </div>
  )
}

export default Content1