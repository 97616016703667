import React, {useState, useEffect} from 'react';
import './Section6.css';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {useParams} from 'react-router-dom';

function Section6() {
  let {id} = useParams();

  const [post, setPost] = useState([]);


  useEffect(() => {
    axios.get(`https://api.logicode.id/api/portfolio/${id}`)
    .then(res => {
        console.log(res)
        setPost(res.data.data[0])
    })
    .catch(err =>{
        console.log(err)
    })
    },[]);
  return (
    <Link key={post.id} to={`/portfoliodetails/${post.prev?.prev_id}`} value="Reload Page" onClick="document.location.reload(true)" className="text-decoration-portfoliodetails-next">
    <div className="portfoliodetails-section6-body">
        <div className="portfoliodetails-links">
            /Next Project - {post.next?.next_client}
        </div>
        <div className="portfoliodetails-hero-title">
        {post.next?.next_client}
        </div>
        <div className="portfoliodetails-hero-sub">
        {post.next?.next_client_description}
        </div>

    </div>
    </Link>
  )
}

export default Section6