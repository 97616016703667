import React, {useState, useEffect, useLayoutEffect} from 'react';
import './Section4.css';
import Image1 from '../../../../assets/scope/1.png';
import Image2 from '../../../../assets/scope/2.png';
import Image3 from '../../../../assets/scope/3.png';
import Image4 from '../../../../assets/scope/4.png';
import {Row, Col} from 'react-bootstrap';

import axios from 'axios';
import {useParams} from 'react-router-dom';

function Section4() {
    let {id} = useParams();

    const [post, setPost] = useState([]);
  
  
    useEffect(() => {
      axios.get(`https://api.logicode.id/api/portfolio/${id}`)
      .then(res => {
          console.log(res)
          setPost(res.data.data[0])
      })
      .catch(err =>{
          console.log(err)
      })
      },[]);


      useLayoutEffect(() => {
         function check(){
            const implement = document.getElementById("implementation");
            const design = document.getElementById("design");
            const ideation = document.getElementById("ideation");
            const support = document.getElementById("support");
            
            if(post.scope_of_work?.[0] === "implementation"){  
                implement.style.opacity= "1";
            }
            if(post.scope_of_work?.[1] === "implementation"){  
                implement.style.opacity= "1";
            }
            if(post.scope_of_work?.[2] === "implementation"){  
                implement.style.opacity= "1";
            }
            if(post.scope_of_work?.[3] === "implementation"){  
                implement.style.opacity= "1";
            }

            if(post.scope_of_work?.[0] === "design"){  
                design.style.opacity= "1";
            }
            if(post.scope_of_work?.[1] === "design"){  
                design.style.opacity= "1";
            }
            if(post.scope_of_work?.[2] === "design"){  
                design.style.opacity= "1";
            }
            if(post.scope_of_work?.[3] === "design"){  
                design.style.opacity= "1";
            }

            if(post.scope_of_work?.[0] === "ideation"){  
                ideation.style.opacity= "1";
            }
            if(post.scope_of_work?.[1] === "ideation"){  
                ideation.style.opacity= "1";
            }
            if(post.scope_of_work?.[2] === "ideation"){  
                ideation.style.opacity= "1";
            }
            if(post.scope_of_work?.[3] === "ideation"){  
                ideation.style.opacity= "1";
            }

            if(post.scope_of_work?.[0] === "support"){  
                support.style.opacity= "1";
            }
            if(post.scope_of_work?.[1] === "support"){  
                support.style.opacity= "1";
            }
            if(post.scope_of_work?.[2] === "support"){  
                support.style.opacity= "1";
            }
            if(post.scope_of_work?.[3] === "support"){  
                support.style.opacity= "1";
            }

        }
        check()
      })

       




  return (
    <div key={post.id} className="portfoliodetails-section4-body">

        <div className="portfoliodetails-section4-sub">
            / Scope of Work
        </div>
        <div className="portfoliodetails-section4-title">
        Our scope
        </div>

        <div className="portfoliodetails-section4-text">
        We have worked with and exceeded the expectation of many clients on their digital projects. Our scope of work has expanded substantially in the last couple of years. For a complete list of our capabilities -- and how we can add value to your business, feel free to contact us through email or phone.
        </div>

        <div className="scope-container">
        <Row>
            <Col xs={6} sm={6} md={6} lg={3} xl={3} >
            <div className="image-scope">
                <img src={Image1} id="ideation" alt="" className="scope-image" />
                <div className="scope-title">
                Ideation
                </div>
            </div>
            </Col>

            <Col xs={6} sm={6} md={6} lg={3} xl={3}>
            <div className="image-scope">
                <img src={Image2} id="design" alt="" className="scope-image" />
                <div className="scope-title">
                Design
                </div>
            </div>
            </Col>

            <Col xs={6} sm={6} md={6} lg={3} xl={3}>
            <div className="image-scope">
                <img src={Image3} id="implementation" alt="" className="scope-image" />
                <div className="scope-title">
                Implementation
                </div>
            </div>
            </Col>

            <Col xs={6} sm={6 } md={6} lg={3} xl={3}>
            <div className="image-scope">
                <img src={Image4} id="support" alt="" className="scope-image" />
                <div className="scope-title">
                Support
                </div>
            </div>
            </Col>
            </Row>
        </div>

    </div>
  )
}

export default Section4