import React, {useRef} from 'react';
import './Section2.css';
import {Row, Col} from 'react-bootstrap';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';
import Img from '../../../../assets/3.jpg';

import {motion, useScroll, useTransform} from 'framer-motion';




function Home2content3() {
  const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end end"]
    });

    const tabitem =useTransform(scrollYProgress, [0, 0.55, 0.9, 1], [0, 1, 1, 1])
    const variants = {
      visible: { opacity: 1, x: 0, transition:{duration: 1} },
      hidden: { opacity: 0, x: -50  },
  }
  return (
    <div ref={containerRef}>
    <motion.div className="tab-content" initial="hidden" whileInView="visible" variants={variants} style={{opacity: tabitem}}>
        <Row nogutters className="image-col">
        <Col xs={12} sm={12} md={12} lg={8} className="tab-align">
        <div className="tab-content-title">DevSecOps Managed Services</div>
        <div classname="tab-content-text">
        The triple combination of development, security, and operations enables your business to innovate, grow, and sustain in the digital market. Logicode DevSecOps service integrates security into all aspects of development and delivery. Be securely swift in your digital product devs with us.
        </div>


         <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1 grey-base">
                            Feel at ease knowing your products development and operations are following the best security practice
                            </div> <br></br>
                    </div>

                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1"/> 
                            <div className="tab-value1 grey-base">
                            Integrated and managed DevSecOps solution
                            </div><br></br>
                    </div>

                    <div className="tab-value1-place">
                        <IoCheckmarkCircleOutline className="value-icon1" />
                            <div className="tab-value1 grey-base">
                            Implement Continuous Integration/Continuous Deployment with a well-rounded security implementation approach
                            </div>
                    </div>
        
        
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} >
          <div className="tab-image-placement">
            <img src={Img} alt="" className="tab-img" />
            </div>
        </Col>
     </Row>
    </motion.div>
    </div>

  )
}

export default Home2content3