import React, {useState, useEffect} from 'react';
import './Client.css';
// import Jago from '../../../../assets/client/jago.png';
// import Nobubank from '../../../../assets/client/nobubank.png';
// import Tiket from '../../../../assets/client/tiket.png';
// import OCBC from '../../../../assets/client/ocbc.png';
// import Ruang from '../../../../assets/client/ruangguru.svg.png';
// import Toko from '../../../../assets/client/tokocrypto.png';
// import Adira from '../../../../assets/client/adira.png';
// import Stockbit from '../../../../assets/client/stockbit.png';
// import { Row, Col} from 'react-bootstrap';

import {motion} from 'framer-motion';
import axios from 'axios';
import {Link} from 'react-router-dom';


function Client() {

  const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios.get('https://api.logicode.id/api/clients')
        .then(res => {
            console.log(res)
            setPosts(res.data.data)
        })
        .catch(err =>{
            console.log(err)
        })
    },[])
  
  return (
    <div className="client-body">
    <motion.div className="client-title" 
    initial="hidden"
    whileInView="visible"
    transition={{ duration: 0.8 }}
    variants={{
      visible: { opacity: 1, scale: 1 },
      hidden: { opacity: 0, scale: 0.5 }
    }}
    >/Trusted by your peers</motion.div>

    <div className="client-list">

    <motion.div id="grid-client"
    initial="hidden"
    whileInView="visible"
    transition={{ duration: 0.8 }}
    variants={{
      visible: { opacity: 1, scale: 1 },
      hidden: { opacity: 0, scale: 0.8 }
    }}
    >
    {posts.map(post => (
                <div  key={post.id}>
                    <Link to="/portfolio">
                    
                    {/* <img className="image" alt="" src={`https://api.mobiusdigital.co.id${post.image_path}`} /> */}
                    <img className="image" alt="" src={`https://api.logicode.id${post.image_path}`} />
                    </Link>
                </div>
            ))}
    </motion.div>










      {/* <motion.div initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0.5 }
            }}>
    <Row noGutters={true} className="image-col">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="client-space">
            <img src ={Jago} alt="" className="image"/>
            <img src ={Nobubank} alt="" className="image" />
            
              <img src ={Tiket} alt="" className="image" />
              <img src={OCBC} alt="" className="image" />
            </Col>
    </Row>
    </motion.div>
    <motion.div initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 }
            }}>
            <Row>
            <Col  xs={12} sm={12} md={12} lg={12} xl={12} className="client-space">
            <img src={Ruang} alt="" className="image" />
            <img src={Toko} alt="" className="image" />
            
            <img src={Adira} alt="" className="image" />
            <img src={Stockbit} alt="" className="image" />
            </Col>
            </Row>
    </motion.div> */}
      
      </div>

    </div>
  )
}

export default Client