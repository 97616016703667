import React from 'react';
import './Loader.css'
import {motion} from 'framer-motion';
import Logic from '../../assets/Logic.png';
import O from '../../assets/o.png';
import De from '../../assets/de.png';



function Loader() {
    const variants = {
        visible: { opacity: 1, y: 0, transition:{duration: 0.8, delay: 1} },
        hidden: { opacity: 0, y: -50 },
        exit: {opacity: 0, y: 50, transition:{duration: 0.8,} }
      };
    
      const variants2 = {
        visible: { opacity: 1, y: 0, transition:{duration: 0.8, } },
        hidden: { opacity: 0, y: 50 },
        exit: {opacity:0, y:-50, transition:{duration: 0.8,}}
      };

      // const variant3 ={
      //   visible: { opacity: 1, transition:{duration: 0.8, } },
      //   hidden: { opacity: 0, },
      // };

      const exit = {
        visible: { opacity: 1, },
        hidden: { opacity: 0, transition:{duration: 0.5, delay: 3} },
        
      }


  return (
    <div >
      <motion.div className="loader-body" initial="visible" animate="hidden"  variants={exit}  >
        <div className="img-place-loader" >
        <motion.img src={Logic} alt="" className="logic-img" initial="hidden" animate="visible"  variants={variants2} />
        <motion.img src={O} alt="" className="o-img" initial="hidden" animate="visible"  variants={variants} />
        <motion.img src={De} alt="" className="de-img" initial="hidden" animate="visible"  variants={variants2} />
        </div>
      </motion.div>
      

    </div>
  )
}

export default Loader