import React, {useRef} from 'react';
import './Section3.css';
import {Row, Col, Accordion} from 'react-bootstrap';

import Img from '../../../../assets/rec1.png';
import CSS from '../../../../assets/language/css.png';
import Golang from '../../../../assets/language/golang.png';
import Html from '../../../../assets/language/html.png';
import Java from '../../../../assets/language/java.png';
import Javascript from '../../../../assets/language/javascript.png';
import Net from '../../../../assets/language/net.png';
import PHP from '../../../../assets/language/php.png';
import Python from '../../../../assets/language/python.png';
import Typescript from '../../../../assets/language/typescript.png';

import Angular from '../../../../assets/frameworks/angular.png';
import Bootstrap from '../../../../assets/frameworks/bootstrap.png';
import Django from '../../../../assets/frameworks/django.png';
import Express from '../../../../assets/frameworks/Expressjs.png';
import Flask from '../../../../assets/frameworks/flask.png';
import Ionic from '../../../../assets/frameworks/ionic.png';
import Laravel from '../../../../assets/frameworks/laravel.png';
import Nativescript from '../../../../assets/frameworks/nativescript.png';
import Node from '../../../../assets/frameworks/node.png';
import ReactIcon from '../../../../assets/frameworks/reacticon.png';
import Spring from '../../../../assets/frameworks/spring.png';
import Tailwind from '../../../../assets/frameworks/tailwind.png';
import Vue from '../../../../assets/frameworks/vue.png';

import Dynamo from '../../../../assets/database/dynamo.png';
import Mariadb from '../../../../assets/database/mariadb.png';
import Mongodb from '../../../../assets/database/mongodb.png';
import Mysql from '../../../../assets/database/mysql.png';
import Postgre from '../../../../assets/database/postgre.png';
import Redis from '../../../../assets/database/redis.png';

import Ansible from '../../../../assets/products/ansible.png';
import Apache from '../../../../assets/products/apache.png';
import Aws from '../../../../assets/products/aws.png';
import Cypress from '../../../../assets/products/cypress.png';
import Docker from '../../../../assets/products/docker.webp';
import Firebase from '../../../../assets/products/firebase.png';
import GoogleCloud from '../../../../assets/products/googlecloud.png';
import Kafka from '../../../../assets/products/kafka.png';
import Katalon from '../../../../assets/products/katalon.png';
import Kubernetes from '../../../../assets/products/kubernetes.png';
import Nsq from '../../../../assets/products/nsq.png';
import Tensorflow from '../../../../assets/products/tensorflow.png';
import Testrail from '../../../../assets/products/testrail.png';
import xray from '../../../../assets/products/xray.png';



import {motion, useTransform, useScroll} from 'framer-motion';




function Section3() {

  const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end end"]
    });


    const opacityvalue1 = useTransform(scrollYProgress,[0, 0.2 , 0.5, 0.6 ,1], [0, 0, 1, 1,1] )

    const variants = {
      visible: { opacity: 1, x: 0,  transition:{duration: 1} },
      hidden: { opacity: 0, x: -50  },
  }

  const variants1 = {
    visible: { opacity: 1, x: 0,  transition:{duration: 1} },
    hidden: { opacity: 0, x: 50  },
}





  return (
    <div className="home-section3-body" ref={containerRef}>
      <motion.div className="home-section3-sub" style={{opacity: opacityvalue1}}>/Our Expertise</motion.div>
      <motion.div className="home-section3-title" style={{opacity: opacityvalue1}}>Our techstacks</motion.div>
      <motion.div className="home-section3-caption" style={{opacity: opacityvalue1}}>
      Our team is ready to implement the solution they design for you. Experts at Logicode are fluent in many building blocks of digital initiatives, making your digital journey a smooth sailing one.
      </motion.div>




      <div className="position">
        <Row>
          <Col xs={12} sm={12} md={12} lg={6}>
          <motion.img src={Img} alt="" className="image-exp" initial="hidden" whileInView="visible" variants={variants} />
          </Col>

          <Col xs={12} sm={12} md={12} lg={6} className="accordion-container">
                <motion.div initial="hidden" whileInView="visible" variants={variants1}>
                <Accordion defaultActiveKey="0" flush className="accordion-customize">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                        <div class="plus alt"></div>
                          Languange</Accordion.Header>
                        <Accordion.Body>
                          <img src={CSS} alt="" className="image-accor" />
                          <img src={Golang} alt="" className="image-accor" />
                          <img src={Html} alt="" className="image-accor" />
                          <img src={Java} alt="" className="image-accor" />
                          <img src={Javascript} alt="" className="image-accor" />
                          <img src={Net} alt="" className="image-accor" />
                          <img src={PHP} alt="" className="image-accor" />
                          <img src={Python} alt="" className="image-accor" />
                          <img src={Typescript} alt="" className="image-accor" />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                        <div class="plus alt"></div>
                          Frameworks</Accordion.Header>
                        <Accordion.Body>
                        <img src={Angular} alt="" className="image-accor" />
                        <img src={Bootstrap} alt="" className="image-accor" />
                        <img src={Django} alt="" className="image-accor" />
                        <img src={Express} alt="" className="image-accor" />
                        <img src={Flask} alt="" className="image-accor" />
                        <img src={Ionic} alt="" className="image-accor" />
                        <img src={Laravel} alt="" className="image-accor" />
                        <img src={Nativescript} alt="" className="image-accor" />
                        <img src={Node} alt="" className="image-accor" />
                        <img src={ReactIcon} alt="" className="image-accor" />
                        <img src={Spring} alt="" className="image-accor" />
                        <img src={Tailwind} alt="" className="image-accor" />
                        <img src={Vue} alt="" className="image-accor" />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                           <div class="plus alt"></div>
                          Database</Accordion.Header>
                        <Accordion.Body>
                        <img src={Dynamo} alt="" className="image-accor" />
                        <img src={Mariadb} alt="" className="image-accor" />
                        <img src={Mongodb} alt="" className="image-accor" />
                        <img src={Mysql} alt="" className="image-accor" />
                        <img src={Postgre} alt="" className="image-accor" />
                        <img src={Redis} alt="" className="image-accor" />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                        <div class="plus alt"></div>
                          Product & Others</Accordion.Header>
                        <Accordion.Body>
                        <img src={Ansible} alt="" className="image-accor" />
                        <img src={Apache} alt="" className="image-accor" />
                        <img src={Aws} alt="" className="image-accor" />
                        <img src={Cypress} alt="" className="image-accor" />
                        <img src={Docker} alt="" className="image-accor" />
                        <img src={Firebase} alt="" className="image-accor" />
                        <img src={GoogleCloud} alt="" className="image-accor" />
                        <img src={Kafka} alt="" className="image-accor" />
                        <img src={Katalon} alt="" className="image-accor" />
                        <img src={Kubernetes} alt="" className="image-accor" />
                        <img src={Nsq} alt="" className="image-accor" />
                        <img src={Tensorflow} alt="" className="image-accor" />
                        <img src={Testrail} alt="" className="image-accor" />
                        <img src={xray} alt="" className="image-accor" />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                </motion.div>
                
          </Col>

        </Row>
      </div>

    </div>
  )
}

export default Section3