import React from 'react';
import './Services.css';
import Header from '../../Components/Navbar/Header';
import { Link } from 'react-router-dom';

import Section1 from './Components/Section1/Section1';
import Section2 from './Components/Section2/Section2';
import Footer from '../../Components/Footer/Footer';

function Services() {
  return (
    <div>
      <Header />
      <div className="services-hero">
        <Link to="/" className="hero-header-link hero-header-link-bold">Home</Link> 
          <div className="hero-header-slash">/</div>
        <Link to="/services" className="hero-header-link ">Services</Link>

        <div className="services-overlay">
        <div className="services-overlay-text">All of your tech solutions, in one place.</div>
        <div className="services-overlay-sub">We use the latest tools and processor inherited from Silicon Valley</div>
      </div>
      </div>

      <Section1 />
      <Section2 />
      <Footer />
     

      



    </div>
  )
}

export default Services