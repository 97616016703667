import React, {useState, useEffect} from 'react';
import './PortfolioDetails.css';
import {Link} from 'react-router-dom';
import Section1 from './Components/Section1/Section1';
import Section4 from './Components/Section4/Section4';
import Section5 from '../Home/Components/Section3/Section3';
import Section6 from './Components/Section6/Section6';
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Navbar/Header';

import axios from 'axios';
import {useParams} from 'react-router-dom';



function PortfolioDetails() {
  let {id} = useParams();

  const [post, setPost] = useState([]);


  useEffect(() => {
    axios.get(`https://api.logicode.id/api/portfolio/${id}`)
    .then(res => {
        console.log(res)
        setPost(res.data.data[0])
    })
    .catch(err =>{
        console.log(err)
    })
    },[])
  return (
    <div key={post.id}>

        <Header />
        <div className="portfoliodetails-hero">
        <Link to="/" className="hero-header-link hero-header-link-bold">Home</Link> 
            <div className="hero-header-slash">/</div>
        <Link to="/portfolio" className="hero-header-link hero-header-link-bold">Portfolios</Link>
            <div className="hero-header-slash">/</div>
        <Link to="/portfoliodetails" className="hero-header-link ">{post.client}</Link>
        
        <div className="portfoliodetails-hero-title">
        {post.client}
        
        </div>

        <div className="portfoliodetails-hero-sub">
        {post.client_description}
        </div>
        </div>

        <Section1 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Footer />

    </div>
  )
}

export default PortfolioDetails