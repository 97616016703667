import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tooltip/dist/react-tooltip.css';
import Home from './Pages/Home/Home';
import Portfolio from './Pages/Portfolio/Portfolio';
import Process from './Pages/Process/Process';
import About from './Pages/About/About';
import Career from './Pages/Career/Career';
import Blog from './Pages/Blog/Blog';
import Services from './Pages/Services/Services';
import Contact from './Pages/Contact/Contact';
import PortfolioDetails from './Pages/PortfolioDetails/PortfolioDetails';
import BlogDetails from './Pages/BlogDetails/BlogDetails';


import './App.css';
import './Components/Progress';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';
import {SlArrowUp} from 'react-icons/sl';



function App() {


  return (
    <>
    <div id="progress">
      <span id="progress-value"><SlArrowUp className="arrow-up" /> </span>
    </div>
      <Router>
        <ScrollToTop />
            <main>
              <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/process" element={<Process />} />
              <Route path="/about" element={<About />} />
              <Route path="/career" element={<Career />} />
              <Route path="/services" element={<Services />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blogdetails/:id" element={<BlogDetails />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/portfoliodetails/:id" element={<PortfolioDetails />} />

              </Routes>
            </main>
          </Router>
     
    </>
  );
}

export default App;
