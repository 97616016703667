import React, {useState, useEffect} from 'react';
import './BlogDetails.css';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {useParams} from 'react-router-dom';


import Header from '../../Components/Navbar/Header';
import Section1 from './Components/Section1/Section1';
import Footer from '../../Components/Footer/Footer';

function BlogDetails() {
  let {id} = useParams();

  const [post, setPost] = useState([]);


  useEffect(() => {
    axios.get(`https://api.logicode.id/api/article/${id}`)
    .then(res => {
        console.log(res)
        setPost(res.data.data[0])
    })
    .catch(err =>{
        console.log(err)
    })
    },[])
  return (
    <div key={post.id}>
      <Header />
      <div className="blogdetails-hero">
      <Link to="/" className="hero-header-link">Home</Link> 
          <div className="hero-header-slash">/</div>
        <Link to="/blog" className="hero-header-link hero-header-link-bold">Blog</Link>
        <div className="hero-header-slash">/</div>
        <Link to="/" className="hero-header-link hero-header-link-bold">{post.title}</Link>

        <div className="blogdetails-header-link-title">
        {post.title}
      </div>

{/* 
      <div className="blogdetails-nav-place" >
      <div className="blogdetails-button" >
      Prev Article
      </div>
    <div className="slash"> /</div>
      <div className="blogdetails-button" >
      Next Article
      </div>
    </div> */}

      </div>

      <Section1 />
      <Footer />


    </div>
  )
}

export default BlogDetails