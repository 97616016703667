import React, {useState, useRef} from 'react';
import './Carousel.css';
import Carousel from 'react-bootstrap/Carousel';
import { motion} from 'framer-motion';

function CarouselTab() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const ref = useRef(null);

    const onPrevClick = () => {
        ref.current.prev();
    };
    const onNextClick = () => {
        ref.current.next();
    };
    const variantcarousel = {
      visible: { opacity: 1, transition:{duration: 1.5, delay: 1.5} },
      hidden: { opacity: 0, },
    };
  
    const varianttitle = {
      visible: { opacity: 1, transition:{duration: 1.9, delay: 1} },
      hidden: { opacity: 0, },
    };
  
    const variantbutton = {
      visible: { opacity: 1, transition:{duration: 2, delay: 1.9} },
      hidden: { opacity: 0, },
    };



  

  return (
    <div className="carousel-body" >


        <Carousel indicators={false} interval={5000} ref={ref} activeIndex={index} onSelect={handleSelect} >
      <Carousel.Item >
      
      <motion.div className="carousel-number" initial="hidden" animate="visible" variants={variantcarousel} >
        /01</motion.div>

      <motion.div className="carousel-title" initial="hidden" animate="visible" variants={varianttitle} >
      Do digital transformation the right way
      </motion.div>

            <motion.div className="carousel-text" initial="hidden" animate="visible" variants={variantcarousel} >
            Let Logicode Indonesia handles all things IT, while you do your business. 
Enjoy our extensive range of services: front-end & back-end development, product development, UI/UX design, DevOps, Quality Assurance, system integration, cloud, and managed service.</motion.div>

      </Carousel.Item>
      <Carousel.Item>
      <div className="carousel-number">/02</div>
                <div className="carousel-title">
                With Logicode as your tech solution partner, the world is your oyster.
                </div>
                <div className="carousel-text">
                Expertise and experience are crucial when deciding the perfect tech consulting team for you. As a team of highly seasoned professionals with a myriad of IT knowledge, we are proud to have both.
                </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-number">/03</div>
                <div className="carousel-title">
                Make your business stand out by going digital
                </div>
                <div className="carousel-text">
                Constantly improving and innovating, our skills are at the top of the class. Have your tech strategy implemented by our highly trained experts.
                </div>
      </Carousel.Item>
        </Carousel>



    <motion.div className="carousel-place" initial="hidden" animate="visible" variants={variantbutton} >
      <div className="carousel-button" onClick={onPrevClick}>
      Prev
      </div>
    <div className="slash"> /</div>
      <div className="carousel-button" onClick={onNextClick}>
      Next
      </div>

    
    </motion.div>


    </div>
  )
}

export default CarouselTab