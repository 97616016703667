import React, {useEffect, useState} from 'react';
import './Section1.css';
import { Row, Col, Button} from 'react-bootstrap';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

import { Tooltip } from 'react-tooltip'

function Section1() {
    let {id} = useParams();

  const [post, setPost] = useState([]);


  useEffect(() => {
    axios.get(`https://api.logicode.id/api/portfolio/${id}`)
    .then(res => {
        console.log(res)
        setPost(res.data.data[0])
    })
    .catch(err =>{
        console.log(err)
    })
    },[])
  return (
    <div className="portfoliodetails-section1-body" key={post.id}>
        <Tooltip anchorId="my-element" />
        <Row>
            <Col>
                <div className="portfoliodetails-section1-sub">
                    / Software as a Services
                </div>
                <div className="portfoliodetails-section1-title">
                    {post.client}
                </div>
                <img src={`https://api.logicode.id/${post.client_logo}`} alt="" className="portfoliodetails-image" />
                
                <div className="portfoliodetails-section1-sub">
                    / Platform
                </div>
                {post.platforms?.map(post => (
                    <img key={post.name} id="my-element" data-tooltip-content={`${post.name}`} src={`https://api.logicode.id/${post.image_path}`} alt="" className="portfoliodetails-platform" />
                   
                ))}

            </Col>


            <Col>
                <div className="portfoliodetails-section1-text">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>{post.project_summary}</ReactMarkdown>
                </div>

                {post.deliverables?.map(post => (
                    <div className="tab-value" key={post}><IoCheckmarkCircleOutline className="value-icon" />{post}</div>

                ))}

                 <a href={`${post.client_website}`} target='_blank'><Button className="visit-button">Visit Website</Button></a>   
            
            </Col>

        </Row>
    </div>
  )
}

export default Section1