import React from 'react';
import './Section1.css';
import Image from '../../../../assets/6.png';
import {Row, Col} from 'react-bootstrap';
import {motion} from 'framer-motion';

function HomeContent2() {


    const variants = {
        visible: { opacity: 1,  transition:{duration: 1} },
        hidden: { opacity: 0,  },
    }

    

  return (
    <div className="content" >
        <Row>
            <Col sm={12} xs={12} md={12} lg={12} xl={6}>
            <motion.div className="homecontent1-image-place" initial="hidden" whileInView="visible" variants={variants}>
                <img src={Image} alt="" className="homecontent-image-section" />
            </motion.div>
            </Col>

            <Col sm={12} xs={12} md={12} lg={12} xl={6}>
            <motion.div className="content-title" initial="hidden" whileInView="visible" variants={variants} >
            Solutions shaped to your digital needs            </motion.div>
            <motion.div className="content-text" initial="hidden" whileInView="visible" variants={variants} >
            Our recommendations, design, and solutions are tailored to your business size, trends, market, and requirements. Implement the right digital workforce strategy for your business.            </motion.div>
            
            </Col>


        </Row>


    </div>
  )
}

export default HomeContent2