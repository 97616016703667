import React from 'react';
import './Footer.css';
import {Link } from 'react-router-dom';
import {Row, Col} from 'react-bootstrap';
import { AiFillLinkedin} from 'react-icons/ai';



function Footer() {
  return (
    <div>
    <div className="footer-background">
      <Link to="/contact" className="text-decoration-none-footer">
      <div className="title">Let's Get in Touch.</div></Link>
      <Row noGutters={true} >
        <Col xs={12} sm={6} lg={2} >
        <div className="footer-text">Mail to:</div>
        <div className="links"><a href="mailto:admin@logicode.id" target='_blank' className="footer-text-decoration">admin@logicode.id</a></div>
        </Col>
        <Col xs={12} sm={6} lg={2}>
        <div className="footer-text">Call us:</div>
        <div className="links"><a href="https://wa.me/6282329347212" target='_blank' className="footer-text-decoration">+62 82329347212</a> </div>
        </Col>
        <Col xs={12} sm={6} lg={2}>
        <div className="footer-text">Connect with us:</div>
        <div className="links">
        <a href="https://www.linkedin.com/company/logicode-indonesia/" target='_blank' className="footer-icons"><AiFillLinkedin /></a> 
        </div>
        </Col>

        <Col xs={12} sm={12} lg={5} >
          <div className="footer-link-place">
            <ul>
            <li> <Link to ="/" className="footer-links">Home</Link></li>
          <li> <Link to="/portfolio" className="footer-links  ">Portfolio</Link></li>
          <li> <Link to="/process" className="footer-links   ">Process</Link></li>
          <li>  <Link to ="/contact" className="footer-links  ">Contact</Link></li>
          
          </ul>
          </div>
          <div className="footer-link-place">
          <ul>
          
          <li> <Link to ="/services" className="footer-links">Services</Link></li>
          <li>  <Link to ="/blog" className="footer-links  ">Blog</Link></li>
          <li>  <Link to ="/career" className="footer-links  ">Career</Link></li>
            </ul>
        </div>
        </Col>

      </Row>


    </div>

    <div className="footer-bottom">LOGICODE Indonesia © All Right Reserved</div>
    </div>
  )
}

export default Footer