import React, {useState, useRef} from 'react';
import './Section2.css';
import Section2Content from './Section2Content';
import Section2NavItem from './Section2NavItem';
import {BsPhoneVibrateFill} from 'react-icons/bs';

import Tab1 from './Home2content';
import Tab2 from './Home2Content2';
import Tab3 from './Home2Content3';

import {motion, useScroll, useTransform} from 'framer-motion';


function Section2() {
    const [activeTab, setActiveTab] = useState("tab1");

    const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end end"]
    });

    const opacityvalue = useTransform(scrollYProgress,[0, 0.5, 1], [0, 1, 1] )
    const zoom = useTransform(scrollYProgress,[0, 0.5 ,1],["0%", "100%" ,"100%"])


    const sub =useTransform(scrollYProgress, [0, 0.5, 1], [0, 1, 1])
    const subposition =useTransform(scrollYProgress, [0, 0.5,  1], [-50, 0, 0])

    const tabnav =useTransform(scrollYProgress, [0, 0.1, 0.25, 0.5, 1], [0, 1, 1, 1, 1])
    

  return (
    <div ref={containerRef}>
    <motion.div className="home-section2" style={{opacity: opacityvalue, scale:zoom}}>
    <motion.div className="home-section2-sub" style={{opacity: sub, y: subposition}}>
    / Our Services</motion.div>
    <motion.div className="home-section2-title" style={{opacity: sub, y: subposition}} >
    Turn your vision into reality with our team of IT professionals</motion.div>
        
    <motion.div className="home-section2-tab" style={{opacity: tabnav}}>
    <ul className="homesection2">
        <Section2NavItem title={
          <div className="home-section2-tab-nav">
            <BsPhoneVibrateFill className="title-icon" />
            <div className="home-section2-tab-title">IT Business Development</div>
          </div>
        } id="tab1" activeTab={activeTab} setActiveTab={setActiveTab} /> 
        <Section2NavItem title={
          <div className="home-section2-tab-nav">
          <BsPhoneVibrateFill className="title-icon" />
          <div className="home-section2-tab-title">Outstanding UI/UX Design</div>
        </div>
        } id="tab2" activeTab={activeTab} setActiveTab={setActiveTab} />

        <Section2NavItem title={
         <div className="home-section2-tab-nav">
         <BsPhoneVibrateFill className="title-icon" />
         <div className="home-section2-tab-title">DevSecOps Managed Services</div>
       </div>
        } id="tab3" activeTab={activeTab} setActiveTab={setActiveTab} />
      </ul>
      <div className="outlet">
      <Section2Content id="tab1"  activeTab={activeTab}>
        <Tab1 />
        </Section2Content>
        <Section2Content id="tab2" activeTab={activeTab}>
        <Tab2 />
        </Section2Content>
        <Section2Content id="tab3" activeTab={activeTab}>
        <Tab3 />
        </Section2Content>
    </div>
    </motion.div>

    </motion.div>
    </div>
  )
}

export default Section2