import React, {useState, useEffect} from 'react';
import './Home.css';
import Video from '../../assets/video.mp4';

import Header from '../../Components/Navbar/Header';
import CarouselTab from './Components/Carousel/Carousel';
import Section1 from './Components/Section1/Section1';
import Section2 from './Components/Section2/Section2';
import Client from './Components/Client/Client';
import Section3 from './Components/Section3/Section3';
import Footer from '../../Components/Footer/Footer';

import { motion} from 'framer-motion';
import Loader from '../../Components/Loader/Loader';





function Home() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() =>{
      setLoading(false)

    },4000)
  }, []);

  const variants = {
    visible: { opacity: 1, transition:{duration: 0.2} },
    hidden: { opacity: 0,  },
  };

  const variants1 = {
    visible: { opacity: 1, transition:{duration: 1, delay: 2.5} },
    hidden: { opacity: 0, },
  };

  const variantsvideo = {
    visible: { opacity: 0.8, transition:{duration: 0.57, delay: 0.5} },
    hidden: { opacity: 0, },
  };


  

  return (
    <div >
      {
        loading  ?   <Loader loading={loading} />
        :
       

                <div>
              <Header />
              <motion.div className="hero" initial="hidden" animate="visible" variants={variants} >

                    <motion.video autoPlay muted loop id="video" initial="hidden" animate="visible" variants={variantsvideo}>  
                      <source src={Video} type="video/mp4"></source>
                    </motion.video>

                    <CarouselTab />
                    <motion.div initial="hidden" animate="visible" variants={variants1}>
                    <a id="scroll-btn" href="#section-2"></a> 
                    </motion.div>
              </motion.div>



              <div id="section-2">
              <Section1/>
              </div>

              <Section2 />
              <Client />
              <Section3 />
              <Footer />
              </div>

      }
    </div>
  )
}

export default Home