import React from 'react';
import './Blog.css';
import {Link} from 'react-router-dom';
import Header from '../../Components/Navbar/Header';
import Footer from '../../Components/Footer/Footer';

import Section1 from './Components/Section1/Section1';
import axios from 'axios';

const baseURL = "https://logicode.gregoriusalvin.my.id/api/platforms";


function Blog() {

  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      console.log("connected");
    });
  }, []);



  return (
    <div>
      <Header />
      <div className="blog-hero">
      <Link to="/" className="hero-header-link hero-header-link-bold">Home</Link> 
          <div className="hero-header-slash">/</div>
        <Link to="/portfolio" className="hero-header-link ">Blog</Link>

        <div className="blog-header-link-title">
        Behind our Digital <br></br> Transformation
      </div>

      <div>
            <a id="scroll-btn" href="#section-2"></a> 
            </div>
      </div>

      <div id="section-2">
        <Section1 />
      </div>
      
      <Footer />



    </div>
  )
}

export default Blog