import React from 'react';
import './Section1.css';
import Image from '../../../../assets/14.png';
import {Row, Col} from 'react-bootstrap';
import {motion} from 'framer-motion';

function HomeContent3() {


    const variants = {
        visible: { opacity: 1,  transition:{duration: 1} },
        hidden: { opacity: 0,   },
    }

    

  return (
    <div className="content" >
        <Row>
            <Col sm={12} xs={12} md={12} lg={12} xl={6}>
            <motion.div className="homecontent1-image-place" initial="hidden" whileInView="visible" variants={variants} >
                <img src={Image} alt="" className="homecontent-image-section" />
            </motion.div>
            </Col>

            <Col sm={12} xs={12} md={12} lg={12} xl={6}>
            <motion.div className="content-title" initial="hidden" whileInView="visible" variants={variants}>
            A team scalable to your needs, compatible with your business, and adaptable to your industry.            
            </motion.div>
            <motion.div className="content-text" initial="hidden" whileInView="visible" variants={variants} >
            Whether you're running a book club or leading a start-up. 
No matter if you're a group of one or a big bunch. 
Our strategy is tuned to your business size and industry needs.            
            </motion.div>
            
            </Col>


        </Row>


    </div>
  )
}

export default HomeContent3