import React from 'react';
import './Section1.css';
import { Row, Col, Accordion} from 'react-bootstrap';
import Image from '../../../../assets/5.jpg';
import { Parallax } from "react-parallax";
import {IoCheckmarkCircleOutline} from 'react-icons/io5';

function Section1() {
  return (
    <div className="services-section1-body">
        <div className="services-section1-sub">
            / Our Services
        </div>
        <div className="services-section1-title">
            Explore What We Do
        </div>

        <Row>
         
          <Col xs={12} sm={12} md={12} lg={6} className="accordion-container">
                <Accordion defaultActiveKey="0" flush className="services-accordion">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                        <div className="services-section1-sub number-margin">/01</div>
                        <div className="accordion-title"> IT Business Development</div>
                          <div class="plus alt"></div>
                          </Accordion.Header>
                        <Accordion.Body>
                            <div className="accordion-text">
                            Stand firm in the digital world with innovative tech solutions. Our business-smart and tech-savvy resources understand your point of view and will take you to your goals quickly.
                            <div className="tab-value2-place">
                                  <IoCheckmarkCircleOutline className="value-icon2" />
                              <div className="tab-value2">
                              Start your digital journey straight away
                              </div> <br></br>
                            </div>
                            <div className="tab-value2-place">
                                  <IoCheckmarkCircleOutline className="value-icon2" />
                              <div className="tab-value2">
                              Experience only global standards in IT practices such as software development, product enhancement, and technical strategy
                              </div> <br></br>
                            </div>
                            <div className="tab-value2-place">
                                  <IoCheckmarkCircleOutline className="value-icon2" />
                              <div className="tab-value2">
                              Innovative and market-proven solutions for a wide range of IT initiatives
                              </div> <br></br>
                            </div>

                        </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                        <div className="services-section1-sub number-margin">/02</div>
                        <div className="accordion-title"> Outstanding User Experience</div>
                          <div class="plus alt"></div>
                          </Accordion.Header>
                        <Accordion.Body>
                        <div className="accordion-text">
                        Customer is king -- we get it too. With UI/UX as your product frontline in facing your customers, it is important to get it right. Our team is a beautiful blend of industry-specific vets and creative all-rounder professionals to deliver your vision.
                        <div className="tab-value2-place">
                                  <IoCheckmarkCircleOutline className="value-icon2" />
                              <div className="tab-value2">
                              Seamless user experience with imaginative UI/UX design
                              </div> <br></br>
                            </div>
                            <div className="tab-value2-place">
                                  <IoCheckmarkCircleOutline className="value-icon2" />
                              <div className="tab-value2">
                              Effective UI/UX solutions from research until go-live
                              </div> <br></br>
                            </div>
                            <div className="tab-value2-place">
                                  <IoCheckmarkCircleOutline className="value-icon2" />
                              <div className="tab-value2">
                              Maximize your business potential with the right UI/IX design
                              </div> <br></br>
                            </div>

                        </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                        <div className="services-section1-sub number-margin">/03</div>
                        <div className="accordion-title"> DevSecOps Managed Services</div>
                          <div class="plus alt"></div>
                          </Accordion.Header>
                        <Accordion.Body>
                        <div className="accordion-text">
                        The triple combination of development, security, and operations enables your business to innovate, grow, and sustain in the digital market. Logicode DevSecOps service integrates security into all aspects of development and delivery. Be securely swift in your digital product devs with us.
                        <div className="tab-value2-place">
                                  <IoCheckmarkCircleOutline className="value-icon2" />
                              <div className="tab-value2">
                              Feel at ease knowing your products development and operations are following the best security practice
                              </div> <br></br>
                            </div>
                            <div className="tab-value2-place">
                                  <IoCheckmarkCircleOutline className="value-icon2" />
                              <div className="tab-value2">
                              Integrated and managed DevSecOps solution
                              </div> <br></br>
                            </div>
                            <div className="tab-value2-place">
                                  <IoCheckmarkCircleOutline className="value-icon2" />
                              <div className="tab-value2">
                              Implement Continuous Integration/Continuous Deployment with a well-rounded security implementation approach
                              </div> <br></br>
                            </div>

                        </div>

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                
          </Col>

          <Col xs={12} sm={12} md={12} lg={6}>
          <Parallax bgImage={Image} alt="" className="services-image-section1" strength={150}>                      
            <div className="services-parallax"></div>
            </Parallax>  
          </Col>

        </Row>



    </div>
  )
}

export default Section1