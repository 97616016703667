import React from 'react';
import './Portfolio.css';
import {Link} from 'react-router-dom';
import Section2 from '../Home/Components/Section2/Section2';
import PortfolioList from './Components/PortfolioList/PortfolioList';
import Client from '../Home/Components/Client/Client';
import Section3 from '../Home/Components/Section3/Section3';
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Navbar/Header';


function Portfolio() {
  return (
    
    <div>
      <Header />
      <div className="portfolio-hero">
        <Link to="/" className="hero-header-link hero-header-link-bold">Home</Link> 
          <div className="hero-header-slash">/</div>
        <Link to="/portfolio" className="hero-header-link ">Portfolio</Link>

      <div className="hero-header-link-title">
      Exceeding your expectations, again and again
      </div>
      </div>
      <Section2 />
      <PortfolioList />
      <Client />
      <Section3 />
      <Footer />






    </div>
  )
}

export default Portfolio